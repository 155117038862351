import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about" id="about">
      <h2>About Us</h2>
      <p>At MCJ Studios, we are driven by a singular mission - to design and implement AI solutions that genuinely improve the lives of its users and the efficiency of businesses. We believe that at the core of every great AI strategy, there is a focus on simplicity, functionality, and user-centric design.</p>
      <p>Our philosophy is rooted in the idea that AI should not add complexity to your operations, but rather, it should simplify them. We strive to create AI solutions that automate routine tasks and complex processes, enabling you to focus on what truly matters - be it growing your business, spending time on strategic tasks, or simply enjoying more of your personal time.</p>
      <p>We're firm believers in the transformative power of AI technology. By streamlining operations and improving efficiency, we help individuals and businesses unlock their full potential, paving the way for increased productivity and success.</p>
      <p>Understanding that our clients come from various industries and different educational backgrounds, we prioritize intuitive AI design. Our solutions are crafted to be easily navigable and user-friendly, ensuring a seamless experience for all users, regardless of their technical prowess.</p>
      <p>Join us on this journey as we continue to challenge the status quo, innovate, and redefine what's possible with AI. We're not just creating AI solutions; we're building a better, more efficient future.</p>
    </div>
  );
}

export default About;

