import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="services" id="services">
      <div className="services-intro">
        <h2>Our Services</h2>
        <p>At MCJ Studios, we specialize in a wide range of AI solutions designed to help your business thrive in the digital age. We pride ourselves on delivering high-quality, tailor-made AI strategies that align with your unique business requirements and objectives.</p>
      </div>
      
      <div className="services-container">
        <div className="services-column">
          <h2>AI Readiness Assessment</h2>
          <h3>Data Audit</h3>
          <p>- Evaluate the quality and quantity of your existing data.</p>
          <p>- Identify gaps and make recommendations for data collection.</p>
          <p>- Assess data storage, management, and privacy practices.</p>

          <h3>Infrastructure Evaluation</h3>
          <p>- Assess your current IT infrastructure's capacity to support AI applications.</p>
          <p>- Evaluate your hardware, software, and network capabilities.</p>
          <p>- Recommend upgrades or changes necessary for AI integration.</p>

          <h3>Skills Assessment</h3>
          <p>- Evaluate the AI-related skills and knowledge within your team.</p>
          <p>- Identify training needs and recommend appropriate training programs.</p>
          <p>- Assess the need for hiring new talent or outsourcing.</p>

          <h3>Process Analysis</h3>
          <p>- Analyze your current business processes to identify potential areas for AI automation.</p>
          <p>- Evaluate the feasibility and potential impact of AI integration in these areas.</p>
          <p>- Develop a prioritized list of processes for AI automation.</p>

          <h3>Strategy Development</h3>
          <p>- Develop a clear AI strategy aligned with your business objectives.</p>
          <p>- Identify key performance indicators (KPIs) to measure the success of your AI initiatives.</p>
          <p>- Create a roadmap for AI adoption, including timelines and milestones.</p>

          <h3>Risk Assessment</h3>
          <p>- Identify potential risks associated with AI adoption, including data privacy and security risks.</p>
          <p>- Develop strategies to mitigate these risks.</p>
          <p>- Ensure compliance with relevant regulations and ethical guidelines.</p>
        </div>

        <div className="services-column">
          <h2>Custom AI Solution Development</h2>
          <h3>Requirement Gathering</h3>
          <p>- Understand your business needs, objectives, and specific use cases for AI.</p>
          <p>- Define the scope of the AI solution, including its features and functionalities.</p>      

          <h3>AI Solution Design</h3>
          <p>- Design the architecture of the AI solution based on your requirements.</p>
          <p>- Select the appropriate AI technologies and algorithms for the solution.</p>
          <p>- Plan the user interface and user experience of the AI solution.</p>

          <h3>Data Preparation</h3>
          <p>- Collect and preprocess the data needed to train the AI models.</p>
          <p>- Handle missing data, outliers, and data transformation.</p>
          <p>- Ensure data privacy and security.</p>

          <h3>Model Training and Testing</h3>
          <p>- Train the AI models using the prepared data.</p>
          <p>- Validate and test the models to ensure they meet the defined objectives.</p>
          <p>- Optimize the models for performance and accuracy.</p>

          <h3>AI Solution Implementation</h3>
          <p>- Integrate the AI solution into your existing IT infrastructure.</p>
          <p>- Ensure the solution works seamlessly with your other systems and processes.</p>
          <p>- Provide documentation and training for users.</p>

          <h3>Maintenance and Support</h3>
          <p>- Provide ongoing maintenance and support for the AI solution.</p>
          <p>- Monitor the performance of the AI solution and make adjustments as needed.</p>
          <p>- Update the AI models as new data becomes available.</p>      
        </div>
      </div>
    </div>
  );
}

export default Services;
