import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <div id="home" className="hero">
      <h1>Welcome to MCJ Studios!</h1>
      <p>Your partner in AI Automation</p>
    </div>
  );
}

export default Hero;

