import React from 'react';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <a href="#home">Home</a>
      <a href="#services">Our Services</a>
      <a href="#about">About Us</a>
      <a href="#contact">Contact Us</a>
    </nav>
  );
}

export default Navbar;


