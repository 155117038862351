import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './main.css';

const Main = () => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;

    // Create a scene
    const scene = new THREE.Scene();

    // Create a camera
    const aspect = window.innerWidth / window.innerHeight;
    const width = 10;
    const height = width / aspect;
    const camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);
    camera.position.set(0, 0, 10);

    // Create a WebGL renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    // Resize canvas on window resize
    window.addEventListener('resize', function() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.render(scene, camera);
    });

    // Create a light
    const light = new THREE.PointLight(0xFFFFFF, 1, 1000);
    light.position.set(0, -4, 1.5);
    scene.add(light);

    // Function to adjust light position
    function adjustLightPosition() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      // Adjust light position based on aspect ratio
      const aspectRatio = width / height;
      if (aspectRatio < 1) { // For screens taller than they are wide (portrait mode)
        light.position.y = -3 / (aspectRatio * 0.5);  // Adjust this value to move the light source lower
      } else { // For screens wider than they are tall (landscape mode)
        light.position.y = -4;
      }
    }

    // On window resize, adjust light position
    window.addEventListener('resize', adjustLightPosition);

    // Adjust light position immediately
    adjustLightPosition();

    // Create an ambient light
    const ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.20); // Adjust the intensity to your liking
    scene.add(ambientLight);

    // Create sphere geometry for blobs, make the blobs responsive to the screen size
    let sphereRadius = window.innerWidth * 0.0004; // adjust the 0.0004 value to change the size of the blobs
    if (window.innerWidth < window.innerHeight && /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // It's a mobile device in portrait mode
      sphereRadius *= 2;  // Adjust multiplier as needed
    }
    let sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);

    // Function to create a new blob
    function createBlob(color) {
      const baseColor = new THREE.Color(color);
      const specularColor = new THREE.Color(color);
      specularColor.multiplyScalar(.02); // make the specular color a lighter version of the base color

      const material = new THREE.MeshPhongMaterial({ 
        color: baseColor,
        shininess: 150,
        specular: specularColor
      });

      const blob = new THREE.Mesh(sphereGeometry, material);
      blob.position.set((Math.random() - 0.5) * width, height / -2, 0);
      blob.velocity = new THREE.Vector3((Math.random() - 0.5) * 0.01, 0.005 + Math.random() * 0.005, 0); // slower y velocity
      scene.add(blob);
      return blob;
    }

    // Array to store blobs and colors
    let blobs = [];
    const colors = [0x0f9ca9, 0x009900, 0xFFFF00];
    let currentColorIndex = 0;

    // Create lava lamp container
    const planeGeometry = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight);
    const planeMaterial = new THREE.MeshPhongMaterial({ color: 0x191970, specular: 0x999999, shininess: 30, side: THREE.DoubleSide });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    scene.add(plane);      

    // Add a counter outside your animate function
    let frameCounter = 0;
    let framesUntilNextBlob = 100;  // Adjust this value as needed

    // Animate function
    function animate() {
      requestAnimationFrame(animate);  // Added this line to continuously call the animate function

      // Create new blobs
      frameCounter++;

      if (frameCounter >= framesUntilNextBlob && Math.random() < 0.01) {
        // Recalculate sphereRadius for each frame
        sphereRadius = window.innerWidth * 0.0002;
        if (window.innerWidth < window.innerHeight && /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) { // Portrait mobile
          sphereRadius *= 10;
        } else if (window.innerWidth > window.innerHeight && /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) { // Landscape mobile
          sphereRadius *= 5; // Adjust this multiplier as needed
        }
        // Recreate the sphereGeometry with the new sphereRadius
        sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);

        blobs.push(createBlob(colors[currentColorIndex]));
        currentColorIndex = (currentColorIndex + 1) % colors.length;
        frameCounter = 0;
        framesUntilNextBlob = 100 + Math.floor(Math.random() * 100);  // Randomly vary the number of frames between blobs
      }

      // Animate and remove blobs
      for (const blob of blobs) {      
        blob.position.add(blob.velocity);

        // Add the bouncing condition for x position
        if (blob.position.x < width / -2 || blob.position.x > width / 2) {
          blob.velocity.x *= -1;
        }

        // Ensure that the blob always moves upward after bouncing
        if (blob.velocity.y < 0) {
          blob.velocity.y *= -1;
        }

        blob.scale.multiplyScalar(0.999);
        if (blob.position.y > height / 2 || blob.scale.x < 0.01) {
          scene.remove(blob);
        }
      }

      blobs = blobs.filter(blob => blob.position.y <= height / 2 && blob.scale.x > 0.01);

      renderer.render(scene, camera);
    }

    // Start the animation
    animate();

    // This is the cleanup function
    return () => {
      window.removeEventListener('resize', adjustLightPosition);
      renderer.dispose();
            // Dispose of blob geometry and material here
            for (const blob of blobs) {
              blob.geometry.dispose();
              blob.material.dispose();
            }     
          };
        }, []);
      
        return (
          <div className="main" ref={containerRef}>
          </div>
        );
      };
      
      // Force a refresh of the page
      
      export default Main;
      
