import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div id="contact" className="contact">
      <h2>Contact Us</h2>
      <p>Looking to embark on your AI integration journey? We'd love to help you navigate! Whether you're exploring AI consulting or ready to develop custom AI solutions, we're eager to hear from you!</p>
      <p class="email"><strong>Email:</strong> <a href="mailto:ai@mcjstudios.co.za" className="email-link">ai@mcjstudios.co.za</a></p>      
    </div>
  );
}

export default Contact;

