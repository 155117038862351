import React from 'react';
import './App.css';
import Main from './Lavalamp/main';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">      
      <Navbar />            
      <Hero />
      <Services />
      <About />      
      <Contact />
      <Main />
      <Footer />
    </div>
  );
}


export default App;
